import { Breadcrumb, ConfigProvider } from 'antd';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { ItemType } from 'antd/lib/breadcrumb/Breadcrumb';
import { useAppSelector } from '@/store/hooks';
import { selectCompanyCount, selectUserInfo } from '@/store/feature/userSlice';

// 根据react-router-dom的路由配置生成面包屑
type BreadcrumbProps = {
  items: ItemType[];
  type?: BreadcrumbType;
};

function itemRender(
  item: ItemType,
  params: any,
  items: ItemType[],
  paths: string[]
) {
  const last = items.indexOf(item) === items.length - 1;
  return last ? (
    <span>{item.title}</span>
  ) : (
    <Link to={`${item.path}`}>{item.title}</Link>
  );
}
function PcBreadcrumb(props: BreadcrumbProps) {
  const isScatteredCustomer =
    useAppSelector(selectUserInfo).isScatteredCustomer;
  const { items, type = 'normal' } = props;
  const companyCount = useAppSelector(selectCompanyCount);
  return (
    <div className={styles.breadcrumb}>
      <ConfigProvider
        theme={{
          components: {
            Breadcrumb: {
              linkColor: '#ef5a28',
              lastItemColor: '#999',
            },
          },
        }}
      >
        {type === 'normal' ? (
          <Breadcrumb separator=">" itemRender={itemRender} items={items} />
        ) : (
          !isScatteredCustomer && (
            <div className={styles.info_type}>
              Your company <span>{companyCount.companyName}</span> currently has{' '}
              {companyCount.freeLicenseCount} unused licenses available{' '}
              {companyCount.freeAnmConLicenseCount > 0 &&
                `& ${companyCount.freeAnmConLicenseCount} unused ampcon licenses available`}
            </div>
          )
        )}
      </ConfigProvider>
    </div>
  );
}

export default PcBreadcrumb;
