import { Layout, theme, Spin, Button } from 'antd';
import {
  matchRoutes,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { CSSProperties, useEffect, useState } from 'react';
import routers from '@/routes/routerConfig';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import Breadcrumb from '@/components/Breadcrumb';
import { useAppSelector } from '@/store/hooks';
import {
  selectBreadcrumbTitle,
  selectGlobalLoading,
} from '@/store/feature/appSlice';
import { selectUserInfo } from '@/store/feature/userSlice';
import Cookies from 'js-cookie';
import { loginOut } from '@/api/user/login';

const cx = classNames.bind(styles);

const { Header, Content, Footer } = Layout;

const headerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#1c1f21',
  color: '#999',
  position: 'relative',
};
const footerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default function AppLayout() {
  const location = useLocation();
  const router = useNavigate();

  const globalLoading = useAppSelector(selectGlobalLoading);
  const userInfo = useAppSelector(selectUserInfo);
  const breadcrumbTitle = useAppSelector(selectBreadcrumbTitle);
  const [breadcrumbType, setBreadcrumbType] =
    useState<BreadcrumbType>('normal'); // ['normal', 'info', 'none'
  const [breadcrumbItems, setBreadcrumbItems] = useState<any[]>([]);
  const [lightTheme, setLightTheme] = useState<boolean>(true);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  useEffect(() => {
    // 返回匹配到的路由数组对象，每一个对象都是一个路由对象˝
    const routes = matchRoutes(routers, location);
    const breadcrumbItems: any[] = [];
    if (routes !== null) {
      const currentRoute = routes[routes.length - 1];
      if (
        location.pathname === currentRoute.pathname &&
        currentRoute.route.meta.theme
      ) {
        setLightTheme(currentRoute.route.meta.theme === 'light');
      } else {
        setLightTheme(false);
      }
      const isInfoBreadcrumb = routes.some(
        (item) => item.route.meta.breadcrumb === 'info'
      );
      const isHideBreadcrumb = routes.some(
        (item) => item.route.meta.breadcrumb === 'none'
      );
      if (isInfoBreadcrumb) {
        return setBreadcrumbType('info');
      } else if (isHideBreadcrumb) {
        return setBreadcrumbType('none');
      } else {
        setBreadcrumbType('normal');
      }
      routes.forEach((item) => {
        const path = item.route.path;
        let title = item.route.meta.title;
        if (path === ':id' && breadcrumbTitle) {
          title = breadcrumbTitle;
        }
        if (item.route.index) {
          // 如果有index属性, 删除上一个路由
          breadcrumbItems.pop();
        }
        breadcrumbItems.push({
          title,
          path,
        });
      });
    }
    setBreadcrumbItems(breadcrumbItems);
  }, [location.pathname, breadcrumbTitle]);

  const logout = async () => {
    const data = await loginOut();
    if (data.code === 200) {
      localStorage.removeItem('token');
      Cookies.remove('picaToken', {
        domain: import.meta.env.VITE_COOKIE_DOMAIN,
      });
      router('/login');
    }
  };

  return (
    <Layout>
      <Header style={headerStyle}>
        {userInfo.userType === 3 && (
          <div className={styles.to_home}>
            <Button
              type="primary"
              style={{ padding: '4px 25px', borderRadius: '20px' }}
              onClick={() => router('/')}
            >
              Home
            </Button>
          </div>
        )}
        <p className={styles.head_text}>
          Welcome <span>{userInfo.userName},</span> to the{' '}
          <span>
            Pica8 license portal {userInfo.userType === 3 ? '' : 'Admin'}!
          </span>
        </p>
        <div className={styles.user_tools}>
          {userInfo.userType !== 3 && (
            <div
              className={styles.change_word}
              onClick={() => router('/passwordChange')}
            >
              <span>Change Password</span>
            </div>
          )}
          <div className={styles.logout} onClick={logout}>
            <span>Logout</span>
          </div>
        </div>
      </Header>
      {breadcrumbType !== 'none' && (
        <Breadcrumb items={breadcrumbItems} type={breadcrumbType}></Breadcrumb>
      )}
      <Content
        style={{
          minHeight: `calc(100vh - ${
            breadcrumbType === 'none' ? '169px' : '219px'
          })`,
          background: colorBgContainer,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className={styles.global_loading}>
          <Spin size="large" fullscreen spinning={globalLoading} />
        </div>
        <Outlet />
      </Content>
      <Footer
        style={footerStyle}
        className={cx({ footer_grey: lightTheme, footer_dark: !lightTheme })}
      >
        <img
          src={
            'https://resource.fs.com/mall/generalImg/20240227153448blan6k.png'
          }
          className={styles.footer_logo}
        />
      </Footer>
    </Layout>
  );
}
