import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appReducer from './feature/appSlice';
import userSlice from './feature/userSlice';
const rootReducer = combineReducers({
  app: appReducer,
  user: userSlice,
});
const persistConfig = {
  key: 'root',
  storage, //指定存储到storage中
  whitelist: ['user'], // 指定需要缓存的reducer
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
// 创建store
export const store = configureStore({
  // feature中创建多个子reducer 最终在这里进行合并
  reducer: persistedReducer,
  // 中间件
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  // redux-devtools-extension何时开启
  devTools: import.meta.env.MODE !== 'production',
});
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
