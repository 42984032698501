import ReactDOM from 'react-dom/client';
import App from '@/App';
import './index.scss';
import 'antd/dist/reset.css';
// import zhCN from 'antd/locale/zh_CN';
// import dayjs from 'dayjs';
// import 'dayjs/locale/zh-cn';
import { ConfigProvider, theme } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import client from './api/query/query.client';
import { PersistGate } from 'redux-persist/integration/react';
import monitor from '@/utils/errorReport';

import * as Sentry from '@sentry/react';

Sentry.init({
  enabled: import.meta.env.MODE !== 'development',
  dsn: 'https://8739f890cdf7e565b03c46125185722b@sentry.fs.com/2',
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      // maskAllText: false,
      // blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// dayjs.locale('zh-cn');
// 全局错误事件上报
const isProduction = import.meta.env.MODE === 'production';
if (isProduction) {
  monitor.start();
}

ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement).render(
  <ConfigProvider
    // locale={zhCN}
    theme={{
      token: {
        colorPrimary: '#ef5a28',
        colorInfo: '#ef5a28',
      },
      components: {
        Select: {
          optionSelectedBg: '#ef5a28',
        },
      },
      algorithm: theme.darkAlgorithm,
    }}
  >
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={client}>
            {/* 添加devtools */}
            {!isProduction ? (
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
            ) : (
              ''
            )}
            <App />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </ConfigProvider>
);
