import Monitor from '@fs-front/monitor';

const monitor = new Monitor({
  applyName: 'pica8-license',
  reportUrl:
    'https://open.feishu.cn/open-apis/bot/v2/hook/f6c38a18-a36e-432c-9147-a4dffdabe779',
  userId: ['geralt'],
  templateReport: [
    {
      title: '用户token：',
      content: localStorage.getItem('token') || '',
    },
  ],
});
export default monitor;

// import { AxiosError } from 'axios';

// function switchTemplate(errorData: Record<string, any>) {
//   switch (errorData.type) {
//     case 'js_error':
//       return {
//         tag: 'div',
//         text: {
//           content: `错误信息: ${errorData.message}\n错误文件: ${errorData.filename}\n错误行号: ${errorData.lineno}\n错误列号: ${errorData.colno}\n错误堆栈: ${errorData?.error?.stack}`,
//           tag: 'plain_text',
//         },
//       };
//     case 'promise_error':
//       return {
//         tag: 'div',
//         text: {
//           content: `错误原因: ${errorData.reason}`,
//           tag: 'plain_text',
//         },
//       };
//     case 'ajax_error':
//       return {
//         tag: 'div',
//         text: {
//           content: `错误状态: ${errorData.status}\n请求方法: ${errorData.method}\n请求地址: ${errorData.url}`,
//           tag: 'plain_text',
//         },
//       };
//     case 'static_resource_error':
//       return {
//         tag: 'div',
//         text: {
//           content: `错误信息: ${errorData.message}\n错误文件: ${errorData.filename}\n错误行号: ${errorData.lineno}\n错误列号: ${errorData.colno}`,
//           tag: 'plain_text',
//         },
//       };
//     default:
//       return {
//         tag: 'div',
//         text: {
//           content: '未知错误类型',
//           tag: 'plain_text',
//         },
//       };
//   }
// }

// class ErrorReporter {
//   private static instance: ErrorReporter;
//   private apiUrl: string;

//   static getInstance(apiUrl: string): ErrorReporter {
//     if (!ErrorReporter.instance) {
//       ErrorReporter.instance = new ErrorReporter(apiUrl);
//     }
//     return ErrorReporter.instance;
//   }

//   constructor(apiUrl: string) {
//     this.apiUrl = apiUrl;
//     this.setupErrorListeners();
//   }
//   setupErrorListeners(): void {
//     console.log('init');
//     // 捕获全局的 JavaScript 错误
//     window.addEventListener('error', this.handleGlobalError.bind(this));

//     // 捕获未捕获的 Promise 错误
//     window.addEventListener(
//       'unhandledrejection',
//       this.handlePromiseError.bind(this)
//     );
//     // // 捕获 AJAX 错误
//     // document.addEventListener('ajaxError', this.handleAjaxError.bind(this));
//     // 捕获静态资源加载错误
//     window.addEventListener('error', this.handleStaticResourceError.bind(this));
//   }

//   private handleGlobalError(event: ErrorEvent): void {
//     this.reportError({
//       type: 'js_error',
//       message: event.message,
//       filename: event.filename,
//       lineno: event.lineno,
//       colno: event.colno,
//       error: event.error.stack,
//     });
//   }

//   private handlePromiseError(event: PromiseRejectionEvent): void {
//     this.reportError({
//       type: 'promise_error',
//       reason: event.reason,
//     });
//   }

//   private handleAjaxError(event: AxiosError): void {
//     this.reportError({
//       type: 'ajax_error',
//       status: event.status,
//       method: event.config?.method,
//       url: event.config?.url,
//     });
//   }

//   private handleStaticResourceError(event: ErrorEvent): void {
//     if (
//       event.target instanceof HTMLScriptElement ||
//       event.target instanceof HTMLLinkElement
//     ) {
//       this.reportError({
//         type: 'static_resource_error',
//         message: event.message,
//         filename: event.filename,
//         lineno: event.lineno,
//         colno: event.colno,
//       });
//     }
//   }

//   private reportError(errorData: Record<string, any>): void {
//     const requestBody = {
//       msg_type: 'interactive',
//       card: {
//         config: {
//           wide_screen_mode: true,
//           enable_forward: true,
//           update_multi: true,
//         },
//         header: {
//           template: 'red',
//           title: {
//             content: 'pica8-license报错\n',
//             tag: 'plain_text',
//           },
//         },
//         elements: [
//           {
//             tag: 'div',
//             text: {
//               content: `报错页面链接：${window.location.href}`,
//               tag: 'lark_md',
//             },
//           },
//           {
//             tag: 'div',
//             text: {
//               content: `错误类型: ${errorData.type}`,
//               tag: 'plain_text',
//             },
//           },
//           {
//             tag: 'hr',
//           },
//           switchTemplate(errorData),
//           {
//             tag: 'hr',
//           },
//           {
//             tag: 'div',
//             text: {
//               content: `用户的token: ${localStorage.getItem('token')}`,
//               tag: 'plain_text',
//             },
//           },
//         ],
//       },
//     };
//     console.log(requestBody);
//     const isDev = import.meta.env.MODE === 'development';
//     // if (isDev) {
//     //   console.log('上报错误数据：', requestBody);
//     //   return;
//     // }
//     // 将错误数据上报到指定的 API
//     // fetch(this.apiUrl, {
//     //   method: 'POST',
//     //   headers: {
//     //     'Content-Type': 'application/json',
//     //   },
//     //   body: JSON.stringify(requestBody),
//     // });
//   }
// }

// export default ErrorReporter;
