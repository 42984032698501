import { Suspense, lazy } from 'react';
import { MetaMenu, AuthRouteObject } from './interface';
import AuthRoute from './AuthRoute';
import { Role } from '@/api/interface';
import { Spin } from 'antd';
import AppLayout from '@/layout/AppLayout';
import { Navigate } from 'react-router-dom';

const modules: any = import.meta.glob('../pages/**/*.tsx'); // 在vite中必须这样动态引入所有组件地址

// 快速导入工具函数
const lazyLoad = (moduleName: string) => {
  const Module = lazy(modules[`../pages/${moduleName}/index.tsx`]);
  return (
    <Suspense fallback={<Spin size="large" fullscreen />}>
      <Module></Module>
    </Suspense>
  );
};

const LicensesPage = lazyLoad('Licenses/Home');
const AddLicense = lazyLoad('Licenses/AddLicense');
const AddPoolLicense = lazyLoad('Licenses/AddPoolLicense');
const LicenseList = lazyLoad('Licenses/List');
const TokenList = lazyLoad('Licenses/TokenList');
const SummaryList = lazyLoad('Licenses/SummaryList');
const AddToken = lazyLoad('Licenses/AddToken');
const PasswordEncrypt = lazyLoad('Licenses/PasswordEncryption');
const AdminHome = lazyLoad('Admin/Home');
const AuthGroups = lazyLoad('Admin/Auth/Groups');
const AddGroup = lazyLoad('Admin/Auth/Groups/AddGroup');
const GroupList = lazyLoad('Admin/Auth/Groups/ListPage');
const DeleteLogsList = lazyLoad('Admin/License/DeleteLogs/List');
const DelLogsDetail = lazyLoad('Admin/License/DeleteLogs/DelLogsDetail');
const EditLogsList = lazyLoad('Admin/License/EditLogs/List');
const EditLogsDetail = lazyLoad('Admin/License/EditLogs/Detail');
const LimitationLogsList = lazyLoad('Admin/License/LimitationLogs/List');
const LimitationLogsDetail = lazyLoad('Admin/License/LimitationLogs/Detail');
const SummaryLogsList = lazyLoad('Admin/License/SummaryLogs/List');
const SummaryLogsDetail = lazyLoad('Admin/License/SummaryLogs/Detail');
const SummaryDetailList = lazyLoad('Admin/License/SummaryDetails/List');
const SummaryDetail = lazyLoad('Admin/License/SummaryDetails/Detail');
const BatchExtendLogs = lazyLoad('Admin/License/BatchExtendLogs/List');
const BatchExtendLogsDetail = lazyLoad('Admin/License/BatchExtendLogs/Detail');
const BatchExtendList = lazyLoad('Admin/License/BatchExtend/List');
const BatchExtendDetail = lazyLoad('Admin/License/BatchExtend/Detail');
const FeatureTypesList = lazyLoad('Admin/License/FeatureTypes/List');
const FeatureTypesDetail = lazyLoad('Admin/License/FeatureTypes/Detail');
const AdminLicensesList = lazyLoad('Admin/License/Licenses/List');
const AdminLicenseDetail = lazyLoad('Admin/License/Licenses/Detail');
const AdminPoolLicenseList = lazyLoad('Admin/License/PoolLicenses/List');
const AdminPoolLicenseDetail = lazyLoad('Admin/License/PoolLicenses/Detail');
const LicenseReportList = lazyLoad('Admin/License/LicenseReport/List');
const LicenseReportDetail = lazyLoad('Admin/License/LicenseReport/Detail');

const AdminLicenseRoute = lazyLoad('Admin/License');
const ClientCompanies = lazyLoad('Admin/Client/Companies');
const CompaniesList = lazyLoad('Admin/Client/Companies/ListPage');
const AddCompany = lazyLoad('Admin/Client/Companies/AddCompany');
const CompanyEditLogs = lazyLoad('Admin/Client/CompanyEditLogs');
const CompanyEditLogsList = lazyLoad('Admin/Client/CompanyEditLogs/ListPage');
const CompanyEditLogsDetail = lazyLoad('Admin/Client/CompanyEditLogs/Detail');
const CompanyPools = lazyLoad('Admin/Client/CompanyPools');
const CompanyPoolsList = lazyLoad('Admin/Client/CompanyPools/ListPage');
const AddCompanyPool = lazyLoad('Admin/Client/CompanyPools/AddCompanyPool');
const UserEditLogs = lazyLoad('Admin/Client/UserEditLogs');
const UsetEditlogsList = lazyLoad('Admin/Client/UserEditLogs/ListPage');
const UserEditLogsDetail = lazyLoad('Admin/Client/UserEditLogs/Detail');
const Users = lazyLoad('Admin/Client/Users');
const UsersList = lazyLoad('Admin/Client/Users/ListPage');
const AddUser = lazyLoad('Admin/Client/Users/AddUser');
const UsersPasswordChange = lazyLoad('Admin/Client/Users/UsersPasswordChange');

const AdminAmpconLicenseRoute = lazyLoad('Admin/Ampcon');
const ParameterConfigurationList = lazyLoad('Admin/Ampcon/Parameter/List');
const ParameterConfigurationDetail = lazyLoad('Admin/Ampcon/Parameter/Detail');
const AmpconLicensesList = lazyLoad('Admin/Ampcon/Licenses/List');
const AmpconLicensesDetail = lazyLoad('Admin/Ampcon/Licenses/Detail');
const AmpconLicenseSummary = lazyLoad('Admin/Ampcon/SummaryDetails/List');
const AmpconLicenseSummaryDetail = lazyLoad(
  'Admin/Ampcon/SummaryDetails/Detail'
);
const AuthLogs = lazyLoad('Admin/Ampcon/AuthLogs');
const AmpconLicenseLogs = lazyLoad('Admin/Ampcon/LicensesLogs');
const AmpconReports = lazyLoad('Admin/Ampcon/Reports');

const CompanyAmpconRoute = lazyLoad('Admin/Client/CompanyAmpcon');
const CompanyAmpconLicensesList = lazyLoad('Admin/Client/CompanyAmpcon/List');
const CompanyAmpconLicensesDetail = lazyLoad(
  'Admin/Client/CompanyAmpcon/Detail'
);

const AmpconLicenses = lazyLoad('Licenses/AmpconLicenses');
const AddAmpconLicense = lazyLoad('Licenses/AddAmpconLicense');
const VerifyRevokeCode = lazyLoad('Licenses/VerifyCode');
const AmpconSummaryList = lazyLoad('Licenses/AmpconSummary');

const PasswordChange = lazyLoad('Admin/PasswordChange');
const Login = lazyLoad('Login');
export const NotFound = lazyLoad('404');
const ErrorPage = lazyLoad('ErrorPage');
const Unauthorized = lazyLoad('Unauthorized');

const routers: AuthRouteObject<MetaMenu>[] = [
  {
    path: '/',
    element: (
      <AuthRoute>
        <AppLayout />
      </AuthRoute>
    ),
    errorElement: ErrorPage,
    meta: {
      title: 'Home',
    },
    children: [
      {
        path: 'licenses',
        element: LicensesPage,
        meta: {
          title: 'Licenses',
          breadcrumb: 'info',
          role: [Role.USER],
        },
        children: [
          {
            index: true,
            element: LicenseList,
            meta: {
              title: 'License',
              theme: 'light',
            },
          },
          {
            path: 'token',
            element: TokenList,
            meta: {
              title: 'License',
              theme: 'light',
            },
          },
          {
            path: 'summary',
            element: SummaryList,
            meta: {
              title: 'License',
              theme: 'light',
            },
          },
          {
            path: 'ampconLicenses',
            element: AmpconLicenses,
            meta: {
              title: 'Ampcon Licenses',
              theme: 'light',
            },
          },
          {
            path: 'ampconLicenseSummary',
            element: AmpconSummaryList,
            meta: {
              title: 'Ampcon License Summary',
              theme: 'light',
            },
          },
        ],
      },
      {
        path: 'addAmpconLicense',
        element: AddAmpconLicense,
        meta: {
          title: 'Add Ampcon License',
          breadcrumb: 'none',
          role: [Role.USER],
        },
      },
      {
        path: 'verifyRevokeCode',
        element: VerifyRevokeCode,
        meta: {
          title: 'Verify Revoke Code',
          breadcrumb: 'none',
          role: [Role.USER],
        },
      },
      {
        path: 'addLicense',
        element: AddLicense,
        meta: {
          title: 'Add License',
          breadcrumb: 'none',
          role: [Role.USER],
        },
      },
      {
        path: 'addPoolLicense',
        element: AddPoolLicense,
        meta: {
          title: 'Add Pool License',
          breadcrumb: 'none',
          role: [Role.USER],
        },
      },
      {
        path: 'addToken',
        element: AddToken,
        meta: {
          title: 'Add Token',
          breadcrumb: 'none',
          role: [Role.USER],
        },
      },
      {
        path: 'passwordEncryption',
        element: PasswordEncrypt,
        meta: {
          title: 'Password Encryption',
          breadcrumb: 'none',
          role: [Role.USER],
        },
      },
      {
        path: '/admin',
        element: AdminHome,
        meta: {
          title: 'Admin',
          theme: 'light',
          breadcrumb: 'none',
          role: [Role.PARTNER, Role.ADMIN],
        },
      },
      {
        path: '/admin/auth/group',
        element: AuthGroups,
        meta: {
          title: 'Group',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: GroupList,
            meta: {
              title: 'Group',
            },
          },
          {
            path: 'add',
            element: AddGroup,
            meta: {
              title: 'Add Group',
            },
          },
          {
            path: ':id',
            element: AddGroup,
            meta: {
              title: 'Add Group',
            },
          },
        ],
      },
      {
        path: '/admin/ampcon/parameterconfiguration',
        element: AdminAmpconLicenseRoute,
        meta: {
          title: 'Parameter configuration',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: ParameterConfigurationList,
            meta: {
              title: 'Parameter configuration',
            },
          },
          {
            path: 'add',
            element: ParameterConfigurationDetail,
            meta: {
              title: 'Add parameter configuration',
            },
          },
          {
            path: ':id',
            element: ParameterConfigurationDetail,
            meta: {
              title: 'Parameter configuration',
            },
          },
        ],
      },
      {
        path: '/admin/ampcon/ampconlicenses',
        element: AdminAmpconLicenseRoute,
        meta: {
          title: 'Ampcon licenses',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: AmpconLicensesList,
            meta: {
              title: 'Ampcon licenses',
            },
          },
          {
            path: 'add',
            element: AmpconLicensesDetail,
            meta: {
              title: 'Add Ampcon licenses',
            },
          },
          {
            path: ':id',
            element: AmpconLicensesDetail,
            meta: {
              title: 'Ampcon licenses',
            },
          },
        ],
      },
      {
        path: '/admin/ampcon/ampconlicensesummarydetails',
        element: AdminAmpconLicenseRoute,
        meta: {
          title: 'Ampcon licenses summary details',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: AmpconLicenseSummary,
            meta: {
              title: 'Ampcon licenses summary details',
            },
          },
          {
            path: ':id',
            element: AmpconLicenseSummaryDetail,
            meta: {
              title: 'Ampcon licenses summary details',
            },
          },
        ],
      },
      {
        path: '/admin/ampcon/ampconauthorizationoperationlogs',
        element: AdminAmpconLicenseRoute,
        meta: {
          title: 'Ampcon authorization operation logs',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: AuthLogs,
            meta: {
              title: 'Ampcon authorization operation logs',
            },
          },
        ],
      },
      {
        path: '/admin/ampcon/ampconlicensesoperationlogs',
        element: AdminAmpconLicenseRoute,
        meta: {
          title: 'Ampcon licenses operation logs',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: AmpconLicenseLogs,
            meta: {
              title: 'Ampcon licenses operation logs',
            },
          },
        ],
      },
      {
        path: '/admin/ampcon/reports',
        element: AdminAmpconLicenseRoute,
        meta: {
          title: 'Ampcon license reporting',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: AmpconReports,
            meta: {
              title: 'Ampcon license reporting',
            },
          },
        ],
      },
      {
        path: '/admin/client/companyampconauthorization',
        element: CompanyAmpconRoute,
        meta: {
          title: 'Company ampcon authorization',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: CompanyAmpconLicensesList,
            meta: {
              title: 'Company ampcon authorization',
            },
          },
          {
            path: 'add',
            element: CompanyAmpconLicensesDetail,
            meta: {
              title: 'Add Company ampcon authorization',
            },
          },
          {
            path: ':id',
            element: CompanyAmpconLicensesDetail,
            meta: {
              title: 'Company ampcon authorization',
            },
          },
        ],
      },
      {
        path: '/admin/license/licensedeletelog',
        element: AdminLicenseRoute,
        meta: {
          title: 'License delete logs',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: DeleteLogsList,
            meta: {
              title: 'License delete logs',
            },
          },
          {
            path: ':id',
            element: DelLogsDetail,
            meta: {
              title: 'License delete logs',
            },
          },
        ],
      },
      {
        path: '/admin/license/licenseeditlog',
        element: AdminLicenseRoute,
        meta: {
          title: 'License edit logs',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: EditLogsList,
            meta: {
              title: 'License edit logs',
            },
          },
          {
            path: ':id',
            element: EditLogsDetail,
            meta: {
              title: 'License edit logs',
            },
          },
        ],
      },
      {
        path: '/admin/license/licenselimitationlog',
        element: AdminLicenseRoute,
        meta: {
          title: 'License limitation logs',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: LimitationLogsList,
            meta: {
              title: 'License limitation logs',
            },
          },
          {
            path: ':id',
            element: LimitationLogsDetail,
            meta: {
              title: 'License limitation logs',
            },
          },
        ],
      },
      {
        path: '/admin/license/licensesummarylog',
        element: AdminLicenseRoute,
        meta: {
          title: 'License summary logs',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: SummaryLogsList,
            meta: {
              title: 'License summary logs',
            },
          },
          {
            path: ':id',
            element: SummaryLogsDetail,
            meta: {
              title: 'License summary logs',
            },
          },
        ],
      },
      {
        path: '/admin/license/licensesummarydetail',
        element: AdminLicenseRoute,
        meta: {
          title: 'License summary details',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: SummaryDetailList,
            meta: {
              title: 'License summary details',
            },
          },
          {
            path: ':id',
            element: SummaryDetail,
            meta: {
              title: 'License summary details',
            },
          },
        ],
      },
      {
        path: '/admin/license/batchextendlog',
        element: AdminLicenseRoute,
        meta: {
          title: 'Batch extend logs',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: BatchExtendLogs,
            meta: {
              title: 'Batch extend logs',
            },
          },
          {
            path: ':id',
            element: BatchExtendLogsDetail,
            meta: {
              title: 'Batch extend logs',
            },
          },
        ],
      },
      {
        path: '/admin/license/extendexpirationdateofbatchlicense',
        element: AdminLicenseRoute,
        meta: {
          title: 'Extend expiration date of batch licenses',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: BatchExtendList,
            meta: {
              title: 'Extend expiration date of batch licenses',
            },
          },
          {
            path: 'add',
            element: BatchExtendDetail,
            meta: {
              title: 'Add extend expiration date of batch licenses',
            },
          },
          {
            path: ':id',
            element: BatchExtendDetail,
            meta: {
              title: 'Extend expiration date of batch licenses',
            },
          },
        ],
      },
      {
        path: '/admin/license/featuretype',
        element: AdminLicenseRoute,
        meta: {
          title: 'Feature types',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: FeatureTypesList,
            meta: {
              title: 'Feature types',
            },
          },
          {
            path: 'add',
            element: FeatureTypesDetail,
            meta: {
              title: 'Add feature types',
            },
          },
          {
            path: ':id',
            element: FeatureTypesDetail,
            meta: {
              title: 'Feature types',
            },
          },
        ],
      },
      {
        path: '/admin/license/licenses',
        element: AdminLicenseRoute,
        meta: {
          title: 'Licenses',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: AdminLicensesList,
            meta: {
              title: 'Licenses',
            },
          },
          {
            path: 'add',
            element: AdminLicenseDetail,
            meta: {
              title: 'Add licenses',
            },
          },
          {
            path: ':id',
            element: AdminLicenseDetail,
            meta: {
              title: 'Licenses',
            },
          },
        ],
      },
      {
        path: '/admin/license/poollicense',
        element: AdminLicenseRoute,
        meta: {
          title: 'Pool licenses',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: AdminPoolLicenseList,
            meta: {
              title: 'Pool licenses',
            },
          },
          {
            path: 'add',
            element: AdminPoolLicenseDetail,
            meta: {
              title: 'Add pool licenses',
            },
          },
          {
            path: ':id',
            element: AdminPoolLicenseDetail,
            meta: {
              title: 'Pool licenses',
            },
          },
        ],
      },
      {
        path: '/admin/client/company',
        element: ClientCompanies,
        meta: {
          title: 'Companies',
          theme: 'dark',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: CompaniesList,
            meta: {
              title: 'Companies',
            },
          },
          {
            path: 'add',
            element: AddCompany,
            meta: {
              title: 'Add Company',
            },
          },
          {
            path: ':id',
            element: AddCompany,
            meta: {
              title: 'Companies',
            },
          },
        ],
      },
      {
        path: '/admin/client/companyeditlog',
        element: CompanyEditLogs,
        meta: {
          title: 'Company edit logs',
          theme: 'dark',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: CompanyEditLogsList,
            meta: {
              title: 'Company edit logs',
            },
          },
          {
            path: ':id',
            element: CompanyEditLogsDetail,
            meta: {
              title: 'Company edit logs',
            },
          },
        ],
      },
      {
        path: '/admin/client/companypool',
        element: CompanyPools,
        meta: {
          title: 'Company pools',
          theme: 'dark',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: CompanyPoolsList,
            meta: {
              title: 'Company pools',
            },
          },
          {
            path: 'add',
            element: AddCompanyPool,
            meta: {
              title: 'Add company pool',
            },
          },
          {
            path: ':id',
            element: AddCompanyPool,
            meta: {
              title: 'Company pools',
            },
          },
        ],
      },
      {
        path: '/admin/client/usereditlog',
        element: UserEditLogs,
        meta: {
          title: 'User edit logs',
          theme: 'dark',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: UsetEditlogsList,
            meta: {
              title: 'User edit logs',
            },
          },
          {
            path: ':id',
            element: UserEditLogsDetail,
            meta: {
              title: 'User edit logs',
            },
          },
        ],
      },
      {
        path: '/admin/client/user',
        element: Users,
        meta: {
          title: 'Users',
          theme: 'dark',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: UsersList,
            meta: {
              title: 'Users',
            },
          },
          {
            path: 'add',
            element: AddUser,
            meta: {
              title: 'Add user',
            },
          },
          {
            path: ':id',
            element: AddUser,
            meta: {
              title: 'Users',
            },
          },
          {
            path: 'password/:id',
            element: UsersPasswordChange,
            meta: {
              title: 'Password',
            },
          },
        ],
      },
      {
        path: '/admin/license/licensereporting',
        element: AdminLicenseRoute,
        meta: {
          title: 'License reporting',
          theme: 'dark',
          role: [Role.PARTNER, Role.ADMIN],
        },
        children: [
          {
            index: true,
            element: LicenseReportList,
            meta: {
              title: 'License reporting',
            },
          },
          {
            path: ':id',
            element: LicenseReportDetail,
            meta: {
              title: 'Report',
            },
          },
        ],
      },
      {
        path: '/passwordChange',
        element: PasswordChange,
        meta: {
          title: 'Password Change',
          role: [Role.PARTNER, Role.ADMIN],
        },
      },
    ],
  },
  {
    path: '/login',
    element: Login,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/unauthorized',
    element: Unauthorized,
    meta: {
      title: 'Unauthorized',
    },
  },
  {
    path: '/accounts/login',
    element: <Navigate to="/" />,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '*',
    element: NotFound,
    meta: {
      title: '404',
    },
  },
];

export default routers;
