import { QueryClient } from 'react-query';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

// client.setQueryDefaults(QUERY_CHAT_TRANSLATE_INPUT, {
//   select: (res) => res.data,
//   enabled: false,
// });

export default client;
