import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

// 类型
export interface AppState {
  globalLoading: boolean;
  breadcrumbTitle: string;
}
// 初始化状态
const initialState: AppState = {
  globalLoading: false,
  breadcrumbTitle: '',
};
// 创建一个Slice切片
export const appSlice = createSlice({
  name: 'app',
  // `createSlice` 将从 `initialState` 参数推断状态类型
  initialState,
  // 定义reducer并添加操作
  reducers: {
    changeLoading: (state, action: PayloadAction<boolean>) => {
      state.globalLoading = action.payload;
    },
    changeBreadcrumbTitle: (state, action: PayloadAction<string>) => {
      state.breadcrumbTitle = action.payload;
    },
  },
});
// 导出方法，对应redux的actions 通过dispatch直接调用
export const { changeLoading, changeBreadcrumbTitle } = appSlice.actions;

// 获取数据的方法
export const selectGlobalLoading = (state: RootState) =>
  state.app.globalLoading;
export const selectBreadcrumbTitle = (state: RootState) =>
  state.app.breadcrumbTitle;
// 导出reducer
export default appSlice.reducer;
