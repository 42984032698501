import { message } from 'antd';
import Axios, {
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { resData } from './interface';

// 统一配置
const baseURL = import.meta.env.VITE_BASE_URL;
export const service = Axios.create({
  baseURL,
  responseType: 'json',
  timeout: 30000,
});

// 请求拦截
service.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// 拦截响应
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const res = response.data;
    //如果是blob类型，直接返回
    if (response.config.responseType === 'blob') {
      return response;
    }
    // 如果有新的token，更新token
    if (response.headers['accesstoken'] !== undefined) {
      const newToken = response.headers['accesstoken'];
      localStorage.setItem('token', newToken);
    }
    // 这块需要修改 根据请求返回成功标志
    if (res.code === 200) {
      return response.data;
    } else {
      if (res.message) {
        message.error(res.message, 3);
      }
      return Promise.reject({ ...res, url: response.config.url });
    }
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      message.error('Unauthorized', 2).then(() => {
        window.location.href = '/login';
      });
    } else {
      message.error(err.message, 3);
    }
    return Promise.reject(err);
  }
);

// 设置返回值和请求值范型
export default function request<Res = any, Q = any>(
  req: AxiosRequestConfig & {
    data?: Q;
  }
) {
  return service(req).then(
    (res) => {
      return res as unknown as resData<Res>;
    },
    (res) => {
      return Promise.reject((res.data || res) as resData<Res>);
    }
  );
}
