import { useEffect } from 'react';
import {
  Navigate,
  matchRoutes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import routers from './routerConfig';
import { useAppSelector } from '@/store/hooks';
import { selectUserInfo } from '@/store/feature/userSlice';
import Cookies from 'js-cookie';

type Props = {
  children: React.ReactNode;
};

function AuthRoute(props: Props) {
  const location = useLocation();
  const userInfo = useAppSelector(selectUserInfo);
  const router = useNavigate();
  const { children } = props;
  const isLogin = localStorage.getItem('token');
  const picaToken = Cookies.get('picaToken');
  if (!isLogin && picaToken) {
    localStorage.setItem('token', picaToken);
  }
  // if (!isLogin || !Cookies.get('picaToken')) {
  //   return <Navigate to="/login" replace />;
  // }

  useEffect(() => {
    if (location.pathname === '/') {
      // 根据不同的角色重定向到首页
      switch (userInfo?.userType) {
        case 1:
        case 2:
          router('/admin');
          break;
        case 3:
          router('/licenses');
          break;
        default:
          router('/login');
          break;
      }
      return;
    }
    const routes = matchRoutes(routers, location);
    if (routes === null) {
      return;
    }
    const isAllowedRoute = routes.some((route) => {
      if (route.route.meta.role) {
        return route.route.meta.role.includes(userInfo?.userType);
      }
    });
    if (!isAllowedRoute) {
      return router('/login');
    }
  }, [location.pathname]);

  return isLogin ? (
    (children as React.ReactElement)
  ) : (
    <Navigate to="/login" replace />
  );
}

export default AuthRoute;
