import { AxiosResponse } from 'axios';

export interface resData<T> extends AxiosResponse<T> {
  success: boolean;
  data: T;
  code: number;
  message: string;
  status: number;
}

export interface LicenseFormOptionData {
  featureTypeList: { label: number; name: string }[];
  speedTypeList: { label: number; name: string }[];
  name: string;
  hardwareId: string;
  companyId?: number;
  expirationDateList: string[];
}

export type AddLicenseFieldType = {
  speedTypeId: string;
  featureTypeId: string;
  hardwareId: string;
  name: string;
  expirationDate: string;
};

export interface AdminMenuType {
  menuList: AdminMenuItemType[];
  title: string;
}
export interface AdminMenuItemType {
  label: string;
  route: string;
  rightList: AdminMenuItemType[] | [];
  permission: {
    hasAdd: boolean;
    hasChange: boolean;
    hasDelete: boolean;
  };
}

export interface DefaultPagination {
  current: number;
  size: number;
  search: string;
}

export interface ExpirationDateParams {
  featureTypeId: number;
  speedTypeId: number;
  mode: string;
  companyId: string;
}
export type noSearchPagination = Omit<DefaultPagination, 'search'>;

export interface AdminLicenseParams {
  comments: string;
  companyId: number;
  expirationDate: string;
  featureTypeId: number;
  hardwareId: string;
  mode: string;
  name: string;
  siteName: string;
  speedTypeId: number;
  id: number;
}

export type UpdateLicenseParams = Omit<
  AdminLicenseParams,
  'companyId' | 'siteName' | 'speedTypeId' | 'featureTypeId'
>;

export type UpdateLicenseKeyParams = Pick<
  AdminLicenseParams,
  'id' | 'hardwareId' | 'expirationDate' | 'featureTypeId'
> & { speedType: string };

export interface UserToken {
  accessToken: string;
  expires: number;
  refreshToken?: string;
  tokenType: string;
  authorities: string[];
}

export interface CompanyCountType {
  companyName: string;
  freeLicenseCount: number;
  freeAnmConLicenseCount: number;
}

export enum Role {
  PARTNER = 1,
  ADMIN,
  USER,
  NONE,
}

export interface ChangePasswordParams {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
}

export interface changeUserPasswordParams
  extends Pick<ChangePasswordParams, 'password' | 'passwordConfirm'> {
  userId: number;
}

export interface LicenseReportParams {
  actionType: string;
  companyName: string;
  endPeriod: string;
  startPeriod: string;
}

export interface HistoryData {
  actionTime: string;
  changeMessage: string;
  id: number;
  userName: string;
}

export interface DownloadExcelParams {
  createDate: [string, string];
  expireDate: [string, string];
  speedTypeId: number;
}

export interface CompanyExcelDownloadParams {
  companyId?: number;
  createDate?: [string, string];
  expireDate?: [string, string];
  speedTypeId?: number;
}

export interface ParameterListParams {
  current: number;
  name?: string;
  parameter?: number;
  size: number;
  softwareType?: number;
}
