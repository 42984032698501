import {
  ChangePasswordParams,
  changeUserPasswordParams,
  UserToken,
} from '../interface';
import request from '../request';
import { UrlMap } from './urlMap';

export const userLogin = (data: { username: string; password: string }) => {
  return request<UserToken>({
    url: UrlMap.USER_LOGIN,
    method: 'post',
    data,
  });
};

export const getUserRole = () =>
  request({ url: UrlMap.GET_USER_ROLE, method: 'get' });

export const postChangePassword = (data: ChangePasswordParams) =>
  request({ url: UrlMap.POST_CHANGE_PASSWORD, method: 'post', data });

export const postChangeUserPassword = (data: changeUserPasswordParams) =>
  request({ url: UrlMap.POST_CHANGE_USER_PASSWORD, method: 'post', data });

export const getTicketJwt = () =>
  request({ url: UrlMap.GET_TICKET_JWT, method: 'get' });

export const loginOut = () =>
  request({
    url: UrlMap.LOGIN_OUT,
    method: 'post',
    headers: { 'X-Logout': 'license' },
  });
